import { useState, useEffect } from "react";
import { defaultMedia } from "utils/custom-media";
import { ViewportType } from "./useViewport.types";

const useViewport = (): ViewportType => {
  const [viewport, setViewport] = useState<string>("");
  const extraSmall = viewport === "extraSmall";
  const small = viewport === "small";
  const medium = viewport === "medium";
  const large = viewport === "large";
  const gteSmall = viewport === "gteSmall";
  const gteMedium = viewport === "gteMedium";
  const lteSmall = viewport === "lteSmall";
  const lteMedium = viewport === "lteMedium";

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia(defaultMedia.extraSmall).matches) {
        setViewport("extraSmall");
      } else if (window.matchMedia(defaultMedia.small).matches) {
        setViewport("small");
      } else if (window.matchMedia(defaultMedia.medium).matches) {
        setViewport("medium");
      } else if (window.matchMedia(defaultMedia.large).matches) {
        setViewport("large");
      } else if (window.matchMedia(defaultMedia.gteSmall).matches) {
        setViewport("gteSmall");
      } else if (window.matchMedia(defaultMedia.gteMedium).matches) {
        setViewport("gteMedium");
      } else if (window.matchMedia(defaultMedia.lteSmall).matches) {
        setViewport("lteSmall");
      } else if (window.matchMedia(defaultMedia.lteMedium).matches) {
        setViewport("lteMedium");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    extraSmall,
    small,
    medium,
    large,
    gteSmall,
    gteMedium,
    lteSmall,
    lteMedium,
  };
};

export default useViewport;
