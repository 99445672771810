//create a styled component

import styled from "@emotion/styled";
import { StylingProps } from "./SwiperComponent.types";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const ArrowPrev = styled("div", {
  shouldForwardProp: (prop: string): boolean => prop !== "customTransform"
})<StylingProps>`
  padding-left: 15px;
  cursor: pointer;

  svg {
    width: 30px;
    fill: var(--primary-color);
  }

  path {
    stroke: var(--primary-color);
    stroke-width: 20px;
  }

  ${({ customTransform }) =>
    css`
      -webkit-transform: translateY(
          ${customTransform ? `${customTransform}%` : "-110%"}
        )
        scale(-1);
      transform: translateY(
          ${customTransform ? `${customTransform}%` : "-110%"}
        )
        scale(-1);
    `}
`;

export const ArrowNext = styled("div", {
  shouldForwardProp: (prop: string): boolean => prop !== "customTransform"
})<StylingProps>`
  padding-left: 15px;
  cursor: pointer;

  svg {
    width: 30px;
    fill: var(--primary-color);
  }

  path {
    stroke: var(--primary-color);
    stroke-width: 20px;
  }

  ${({ customTransform }) =>
    css`
      -webkit-transform: translateY(
        ${customTransform ? `${customTransform}%` : "-110%"}
      );
      transform: translateY(
        ${customTransform ? `${customTransform}%` : "-110%"}
      );
    `}
`;
