import ReactFullpage from "@fullpage/react-fullpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  LoaderItem,
  PageWrapper,
  ParallaxBackground,
  PreLoader,
  PreLoaderBounce,
  SocialIcon,
  SocialIconLink,
  SocialIconsContainer,
  SocialIconsList,
} from "./Homepage.styles";

import Header from "components/header/Header";
import { headerLinks } from "utils/header-links";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const Homepage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  // const scrollablePages = ["Timeline", "About"];

  useEffect(() => {
    const onPageLoad = () => {
      setIsLoading(false);
    };

    let showPage;

    setTimeout(() => {
      showPage = true;
      onPageLoad();
    }, 2000);

    if (document.readyState === "complete" && showPage === true) {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  const preLoader = (
    <>
      <PreLoader isLoaded={!isLoading}>
        <PreLoaderBounce>
          <LoaderItem />
          <LoaderItem />
          <LoaderItem />
        </PreLoaderBounce>
      </PreLoader>
    </>
  );

  const socialIcons = (
    <SocialIconsContainer>
      <SocialIconsList>
        <SocialIcon>
          <SocialIconLink
            title="Linkedin"
            href="https://www.linkedin.com/in/luccas-rodrigues/"
            target="_blank"
          >
            <FontAwesomeIcon color="white" icon={faLinkedinIn as IconProp} />
          </SocialIconLink>
        </SocialIcon>
        <SocialIcon>
          <SocialIconLink
            title="Github"
            href="https://github.com/luccasro"
            target="_blank"
          >
            <FontAwesomeIcon color="white" icon={faGithub as IconProp} />
          </SocialIconLink>
        </SocialIcon>
      </SocialIconsList>
    </SocialIconsContainer>
  );

  const pluginWrapper = () => {
    require("../utils/fullpage.parallax.min.js");
  };

  return (
    <>
      {!isLoading && <Header />}
      {preLoader}
      {socialIcons}
      <ReactFullpage
        pluginWrapper={pluginWrapper}
        scrollOverflow
        autoScrolling
        credits={{ enabled: false }}
        recordHistory
        slidesNavigation
        parallax
        scrollingSpeed={1000}
        parallaxOptions={{ percentage: 62 }}
        render={({ state, fullpageApi }) => {
          const locationIndex =
            headerLinks.findIndex((link) => link.href === location.hash) + 1;
          if (location.hash) {
            fullpageApi?.moveTo(locationIndex, 0);
          }

          return (
            <PageWrapper id="fullpage-wrapper">
              {headerLinks.map((headerLink, index) => {
                // const isHome =
                //   index === 0 &&
                //   location.pathname === "/" &&
                //   location.hash === "";
                // const isActive = isHome || location.hash === headerLink.href;
                // const isScrollable = scrollablePages.some(
                //   (page) => page === headerLink.name
                // )
                //   ? "slide-scrollable"
                //   : "";

                return (
                  <div
                    key={`homepage-${headerLink.name}`}
                    className={`section slide-scrollable`}
                    data-anchor={headerLink.href.replace("#", "")}
                  >
                    <ParallaxBackground className={`fp-bg`} index={index + 1} />
                    <div style={{ display: isLoading ? "none" : "" }}>
                      {headerLink.component}
                    </div>
                  </div>
                );
              })}
            </PageWrapper>
          );
        }}
      />
    </>
  );
};
