import { Fragment, useState } from "react";

import RevealEffect from "components/reveal-effect/RevealEffect";
import SwiperComponent from "components/swiper-component/SwiperComponent";
import useViewport from "hooks/useViewport/useViewport";

import {
  ContentWrapper,
  ProjectBox,
  Title,
  Wrapper,
  Image,
  ProjectWrapper,
  ProjectDescription,
  DescriptionLink,
  DescriptionWrapper,
} from "./Projects.styles";
import { SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const Projects: React.FC = () => {
  const { extraSmall, small, medium, large } = useViewport();
  const [hoveredItem, setHoveredItem] = useState<number | undefined>(undefined);

  type ProjectItem = {
    name: string;
    source: string;
    description: JSX.Element;
    links: JSX.Element;
  };

  const projectList: ProjectItem[] = [
    {
      name: "SmartScale",
      source: "images/projects/smartscale.png",
      description: (
        <>
          A weight management web app with detailed graphs and stats. Set and
          reach your goals.
          <br /> <br />
          <b>
            Technologies: React, HTML, CSS, TypeScript, Firebase, Tailwind,
            Material UI.
          </b>
          <br />
        </>
      ),
      links: (
        <DescriptionWrapper>
          <DescriptionLink
            className="bold"
            href="https://smartscale.luccasdev.com"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faUpRightFromSquare as IconProp}
            />
            Demo
          </DescriptionLink>
          <DescriptionLink
            className="bold"
            href="https://github.com/luccasro/smartscale"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faGithub as IconProp}
            />
            Github
          </DescriptionLink>
        </DescriptionWrapper>
      ),
    },
    {
      name: "Proffy",
      source: "images/projects/proffy.png",
      description: (
        <>
          A web app facilitating the viewing and creation of classes, offering
          detailed information about the teacher and the subject.
          <br /> <br />
          <b>
            Technologies: React, HTML, CSS, TypeScript, Node.js, PostgreSQL.
          </b>
          <br />
        </>
      ),
      links: (
        <DescriptionWrapper>
          <DescriptionLink
            className="bold"
            href="https://proffy.luccasdev.com"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faUpRightFromSquare as IconProp}
            />
            Demo
          </DescriptionLink>
          <DescriptionLink
            className="bold"
            href="https://github.com/luccasro/proffy"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faGithub as IconProp}
            />
            Github
          </DescriptionLink>
        </DescriptionWrapper>
      ),
    },
    {
      name: "Ideal Weight",
      source: "images/projects/idealweight.png",
      description: (
        <>
          An Android app for weight progress tracking, featuring a BMI
          calculator and. It supports multiple languages
          <br />
          <br />
          <b>Technologies: React Native, Expo, TypeScript.</b>
          <br />
        </>
      ),
      links: (
        <DescriptionWrapper>
          <DescriptionLink
            className="bold"
            href="https://play.google.com/store/apps/details?id=io.luccasdev.calculadoraimc"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faGooglePlay as IconProp}
            />
            Play Store
          </DescriptionLink>
        </DescriptionWrapper>
      ),
    },
    {
      name: "Tinzzio",
      source: "images/projects/tinzzio.png",
      description: (
        <>
          A hybrid app for web and mobile to create and play quizzes.
          <br />
          <br />
          <b>
            Technologies: React Native, Expo, TypeScript, Native Base, Firebase.
          </b>
          <br />
        </>
      ),
      links: (
        <DescriptionWrapper>
          <DescriptionLink
            className="bold"
            href="https://tinzzio.luccasdev.com/"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faUpRightFromSquare as IconProp}
            />
            Demo
          </DescriptionLink>
          <DescriptionLink
            className="bold"
            href="https://github.com/luccasro/tinzzio"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faGithub as IconProp}
            />
            Github
          </DescriptionLink>
        </DescriptionWrapper>
      ),
    },
    {
      name: "Coming Soon",
      source: "images/projects/comingsoon.png",
      description: <>This project is under construnction.</>,
      links: <></>,
    },
  ];

  const handleMouseEnter = (index: number) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(undefined);
  };

  const handleOnClick = (index: number) => {
    setHoveredItem(index === hoveredItem ? undefined : index);
  };

  const slidesPerView = extraSmall ? 1 : small ? 2 : medium ? 3 : large ? 4 : 3;

  const showNavigation = projectList.length !== slidesPerView && !extraSmall;

  const renderProjectItem = (project: ProjectItem, index: number) => {
    return (
      <ProjectBox
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        onClick={() => extraSmall || (small && handleOnClick(index))}
      >
        <Image draggable={false} src={project.source} />
        <ProjectDescription isHovered={hoveredItem === index}>
          <h4 className="bold">{project.name}</h4>
          <p>{project.description}</p>
        </ProjectDescription>
      </ProjectBox>
    );
  };

  const renderProjectItem2 = (project: ProjectItem, index: number) => {
    return (
      <article className="card">
        <Image
          className="card__background"
          width="1920"
          height="2193"
          draggable={false}
          src={project.source}
        />

        <div className="card__content | flow">
          <div className="card__content--container | flow">
            <h2 className="card__title bold">{project.name}</h2>
            <p className="card__description">{project.description}</p>
          </div>
          {project.links}
        </div>
        {/* <button className="card__button">Read more</button> */}
      </article>
    );
  };

  return (
    <Wrapper>
      <RevealEffect effect="fade" direction="up">
        <div>
          <Title>
            Projects <span>({projectList.length})</span>
          </Title>
          <h2>What I've done?</h2>
        </div>
      </RevealEffect>
      <ContentWrapper>
        {/* <article className="card">
          <img
            className="card__background"
            src="images/projects/tinzzio.png"
            alt="Cartagena's cathedral at the background and some colonial style houses"
            width="1920"
            height="2193"
          />
          <div className="card__content | flow">
            <div className="card__content--container | flow">
              <h2 className="card__title">Tinzzio</h2>
              <p className="card__description">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum
                in labore laudantium deserunt fugiat numquam.
              </p>
            </div>
            <button className="card__button">Read more</button>
          </div>
        </article> */}
        <RevealEffect delay={1500} duration={1000} direction="down">
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "stretch",
              gap: "20px"
            }}
          >
            <div style={{ width: "50%" }}> */}
          {false ? (
            <ProjectWrapper>
              {projectList.map((project, index) => (
                <Fragment key={`project-${index}`}>
                  <RevealEffect
                    delay={index === 0 ? 200 : index === 1 ? 400 : 600}
                    duration={1000}
                    direction="down"
                  >
                    {renderProjectItem(project, index)}
                  </RevealEffect>
                </Fragment>
              ))}
            </ProjectWrapper>
          ) : (
            <SwiperComponent
              grabCursor
              spaceBetween={25}
              slidesPerView={slidesPerView}
              navigation={showNavigation}
              customTransform={-75}
              autoplay={false}
            >
              {projectList.map((project, index) => (
                <SwiperSlide key={`project-${index}`}>
                  <RevealEffect
                    delay={index * 200}
                    duration={1000}
                    direction="down"
                  >
                    <div className="card-wrapper">
                      {renderProjectItem2(project, index)}
                    </div>
                  </RevealEffect>
                </SwiperSlide>
              ))}
            </SwiperComponent>
          )}
        </RevealEffect>
      </ContentWrapper>
    </Wrapper>
  );
};
