import styled from "@emotion/styled";
import { media } from "utils/custom-media";

export const Wrapper = styled.div`
  padding: 0 10% 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.largeMedia} {
    padding: 0 20% 0;
  }

  ${media.lteSmallMedia} {
    padding: 0 5% 0;
  }
`;

export const ContentWrapper = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  ${media.extraSmallMedia} {
    flex-direction: column;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-regular);
`;

export const ContactBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background: rgba(250, 250, 250, 0.25);
  border-radius: 10px;
  padding: 30px;
  // width: 100%;

  & svg {
    padding-right: 20px;
    height: 30px;
  }

  ${media.lteSmallMedia} {
    min-width: 100%;
    padding: 15px;
    font-size: 14px;

    & svg {
      height: 20px;
    }
  }
`;

export const LeftWrapper = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

export const RightWrapper = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

export const ContactInput = styled.input`
  height: 50px;
  color: #fff;
  border: none;
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
  padding: 4px 22px;
  font-size: 14px;
  margin-bottom: 30px;
  outline: none;
  width: 100%;
  transition: all 0.4s ease;

  &:focus {
    box-shadow: none;
    outline: none;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(250, 250, 250, 1);
    color: #fff;
  }

  &hover {
    opacity: 0.7;
  }

  &::placeholder {
    color: white;
  }
`;

export const ContactTextarea = styled.textarea`
  height: 123px;
  color: #fff;
  border: none;
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
  padding: 4px 22px;
  padding-top: 15px;
  font-size: 14px;
  margin-bottom: 30px;
  outline: none;
  width: 100%;
  transition: all 0.4s ease;
  resize: none;

  &:focus {
    box-shadow: none;
    outline: none;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(250, 250, 250, 1);
    color: #fff;
  }

  &::placeholder {
    color: white;
  }
`;

export const SubmitButton = styled.button`
  background: #fff;
  color: #222;
  text-align: center;
  line-height: 52px;
  padding: 0 35px;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: all 0.4s ease;
  width: 100%;

  &:hover {
    color: #fff;
    border-color: #fff;
    background: none;
  }

  ${media.extraSmallMedia} {
    line-height: 46px;
  }
`;
