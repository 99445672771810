import { Badge, ContentWrapper, Title, Wrapper } from "./Timeline.styles.";
import RevealEffect from "components/reveal-effect/RevealEffect";
import {
  faBriefcase,
  faStar,
  faGraduationCap,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useHideContentHash from "hooks/useHideContentHash";

export const Timeline: React.FC = () => {
  const hideContent = useHideContentHash("#timeline");

  const timelineData = [
    {
      date: "May 2022 - Present",
      title: "Front-end Developer",
      subtitle: "Present Technologies • Porto, Portugal",
      description:
        "Develop new features and mantain multiple e-commerce websites. Implement additional functionalities, resolve bugs, ensure responsive layouts, and conduct code reviews and unit testing.<br /><br/> <b>Technologies:</b> React, Redux, Remix, JavaScript, TypeScript, CSS, SASS/SCSS, Styled-components, Jira, Git, GitLab, Docker",
      icon: faBriefcase,
      iconStyle: { background: "#017BAC", color: "#fff" },
    },
    {
      date: "Mar 2022 - May 2022",
      title: "OHC London Intensive English",
      subtitle: "Oxford House College • London, United Kingdom",
      description:
        "One month of intensive English language study at the B2 proficiency level.",
      icon: faLanguage,
      iconStyle: { background: "#017BAC", color: "#fff" },
    },
    {
      date: "May 2021 - May 2022",
      title: "Front-end Developer",
      subtitle: "Frontfiles • Lisbon, Portugal",
      description:
        "Collaborate on the development of a web application, contributing to the implementation of new features, bug fixing, and a responsive layout.<br /><br/><b>Technologies:</b> React, Redux, TypeScript, CSS, Jira, BitBucket, Git",
      icon: faBriefcase,
      iconStyle: { background: "#017BAC", color: "#fff" },
    },
    {
      date: "Feb 2020 - May 2021",
      title: "Front-end Developer",
      subtitle: "Clave Consultoria • Rio de Janeiro, Brazil",
      description:
        "Design and develop an Assessment Dashboard along with assessment tools, implementing new features. Ensure a responsive layout and compatibility with major browsers, and create automated charts and reports to enhance functionality.<br /><br/><b>Technologies:</b> React, React Native, JavaScript, Expo, CSS, Bootstrap, Material Design, Tailwind, Git",
      icon: faBriefcase,
      iconStyle: { background: "#017BAC", color: "#fff" },
    },
    {
      date: "Aug 2019 - Feb 2020",
      title: "Front-end Developer Intern",
      subtitle: "Federal Regional Court 2º Region • Rio de Janeiro, Brazil",
      description:
        "Implement news features and mantain the intranet and website.<br/><br/><b>Technologies:</b> HTML, CSS, JavaScript, jQuery, Bootstrap",
      icon: faBriefcase,
      iconStyle: { background: "#017BAC", color: "#fff" },
    },
    {
      date: "Aug 2017 - Dec 2021",
      title: "Bachelor of Technology",
      subtitle: "UniCarioca University Center • Rio de Janeiro, Brazil",
      description: "Degree in System Analysis and Development",
      icon: faGraduationCap,
      iconStyle: { background: "#017BAC", color: "#fff" },
    },
  ];

  if (hideContent) {
    return <></>;
  }

  return (
    <div>
      <Wrapper>
        <RevealEffect effect="fade" duration={1500} direction="up">
          <>
            <Title>Timeline</Title>
            <h2>My work experience</h2>
          </>
        </RevealEffect>
        <ContentWrapper>
          <VerticalTimeline>
            {timelineData.map((item, index) => (
              <VerticalTimelineElement
                key={`timeline-${index}`}
                className="vertical-timeline-element--work"
                contentStyle={{
                  backgroundColor: "rgba(19, 31, 47, 0.9)",
                  boxShadow: "1px 3px 9px rgba(0, 0, 0, 0.1)",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid rgb(33, 150, 243)",
                }}
                date={item.date}
                iconStyle={item.iconStyle}
                icon={<FontAwesomeIcon icon={item.icon} />}
              >
                <Badge>
                  <h3 className="bold">{item.title}</h3>
                </Badge>
                <h4 className="vertical-timeline-element-subtitle">
                  {item.subtitle}
                </h4>
                <p dangerouslySetInnerHTML={{ __html: item.description }} />
              </VerticalTimelineElement>
            ))}
            <VerticalTimelineElement
              iconStyle={{ background: "#fff", color: "#017BAC" }}
              icon={<FontAwesomeIcon icon={faStar} />}
            />
          </VerticalTimeline>
        </ContentWrapper>
      </Wrapper>
    </div>
  );
};
