import styled from "@emotion/styled";
import { media } from "utils/custom-media";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.gteSmallMedia} {
    padding: 0 10% 0;
  }
`;

export const ContentWrapper = styled.div`
  // flex: 0 0 70%;
  // max-width: 70%;
  width: 100%;
  text-align: left;
`;

export const Badge = styled.div`
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: bold;
  background: #3b4449;
  width: fit-content;
  text-transform: uppercase;
  margin-bottom: 1em;
`;

export const Title = styled.span`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-regular);
`;
