export const defaultMedia = {
  extraSmall: "(max-width: 47.94em)", // <= 767px
  small: "(min-width: 48em) and (max-width: 63.94em)", // >= 768px && <= 1023
  medium: "(min-width: 64em) and (max-width: 89.94em)", // >= 1024 && <= 1439
  large: "(min-width: 90em)", // >= 1440px

  gteSmall: "(min-width: 48em)", // >= 768px
  gteMedium: "(min-width: 64em)", // >= 1024px

  lteSmall: "(max-width: 63.94em)", // <= 1023px
  lteMedium: "(max-width: 89.94em)" // <= 1439px
};

export const media = {
  /* Prefixed with @media */
  extraSmallMedia: `@media ${defaultMedia.extraSmall}`, // <= 767px
  smallMedia: `@media ${defaultMedia.small}`, // >= 768px && <= 1023
  mediumMedia: `@media ${defaultMedia.medium}`, // >= 1024 && <= 1439
  largeMedia: `@media ${defaultMedia.large}`, // >= 1440px

  gteSmallMedia: `@media ${defaultMedia.gteSmall}`, // >= 768px
  gteMediumMedia: `@media ${defaultMedia.gteMedium}`, // >= 1024px

  lteSmallMedia: `@media ${defaultMedia.lteSmall}`, // <= 1023px
  lteMediumMedia: `@media ${defaultMedia.lteMedium}`, // <= 1439px

  /* Safari */
  // These are only being used to overcome this Safari problem: https://bugs.webkit.org/show_bug.cgi?id=159635
  extraSmallSafari: "(max-width: 76.7em)", // <= 767px
  smallSafari: "(min-width: 76.8em) and (max-width: 102.3em)", // >= 768px && <= 1023
  mediumSafari: "(min-width: 102.4em) and (max-width: 143.9em)", // >= 1024 && <= 1439
  largeSafari: "(min-width: 144em)", // >= 1440px

  responsive: {
    extraSmall: 767,
    small: 1023,
    medium: 1439
  }
};
