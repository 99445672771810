import React from "react";
import { SwiperComponentProps } from "./SwiperComponent.types";
import { ArrowNext, ArrowPrev, Wrapper } from "./SwiperComponent.styles";
import { Swiper } from "swiper/react";
import { Autoplay, Navigation, Pagination, Grid } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
import "./styles.css";

const SwiperComponent: React.FC<SwiperComponentProps> = ({
  children,
  navigation,
  customTransform,
  ...remainingProps
}) => {
  const defaultModules = [Pagination, Autoplay, Grid];
  const modules = navigation ? [...defaultModules, Navigation] : defaultModules;

  const arrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      width="20px"
      version="1.1"
      viewBox="0 0 330 330"
    >
      <path d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001  c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213  C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606  C255,161.018,253.42,157.202,250.606,154.389z" />
    </svg>
  );

  return (
    <Wrapper>
      {navigation && (
        <ArrowPrev
          customTransform={customTransform}
          className="swiper-button prev-button"
        >
          {arrow}
        </ArrowPrev>
      )}
      <Swiper
        pagination={{
          clickable: true
        }}
        autoplay
        navigation={
          navigation && {
            nextEl: ".next-button",
            prevEl: ".prev-button"
          }
        }
        modules={modules}
        {...remainingProps}
      >
        {children}
      </Swiper>
      {navigation && (
        <ArrowNext
          customTransform={customTransform}
          className="swiper-button next-button"
        >
          {arrow}
        </ArrowNext>
      )}
    </Wrapper>
  );
};

export default SwiperComponent;
