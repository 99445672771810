import {
  CircleLogo,
  HeaderList,
  HeaderWrapper,
  LogoContainer,
  Menu,
  NameWrapper,
  NavLink,
  OpenMenuButton,
  SmallName,
} from "./Header.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import RevealEffect from "components/reveal-effect/RevealEffect";
import { headerLinks } from "utils/header-links";
import useViewport from "hooks/useViewport/useViewport";

export default function Header() {
  const location = useLocation();
  const { extraSmall, small } = useViewport();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    if (extraSmall || small) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [extraSmall, small]);

  return (
    <HeaderWrapper ref={menuRef}>
      <LogoContainer href="/">
        <RevealEffect
          effect="fade"
          delay={100}
          direction="left"
          duration={1500}
        >
          <div className="flex">
            {/* <CircleLogo>
              <img
                alt="pixeled"
                width={60}
                height={60}
                src="./images/mepixeled2bordered.png"
              />
            </CircleLogo> */}
            <NameWrapper>
              <div>Luccas</div>
              {/* <div>
                <SmallName>RODRIGUES </SmallName>
              </div> */}
            </NameWrapper>
          </div>
        </RevealEffect>
      </LogoContainer>
      <OpenMenuButton onClick={handleOpen}>
        <FontAwesomeIcon color="white" icon={faBars} />
      </OpenMenuButton>
      <Menu isOpen={isOpen}>
        <HeaderList>
          {headerLinks.map((link, index) => {
            const isHome =
              index === 0 && location.pathname === "/" && location.hash === "";
            const isActive = isHome || location.hash === link.href;

            return (
              <li key={`headerItem-${index}`}>
                <RevealEffect
                  delay={200 * index}
                  direction="up"
                  duration={1500}
                  style={{ overflow: "visible" }}
                  disabled={extraSmall || small}
                >
                  <NavLink
                    onClick={handleOpen}
                    href={link.href}
                    isActive={isActive}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "5px" }}
                      icon={link.icon}
                    />
                    {link.name}
                  </NavLink>
                </RevealEffect>
              </li>
            );
          })}
        </HeaderList>
      </Menu>
    </HeaderWrapper>
  );
}
