import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useHideContentHash = (hash: string): boolean => {
  const location = useLocation();
  const [hideContent, setHideContent] = useState(false);

  useEffect(() => {
    if (location.hash !== hash) {
      const timeoutId = setTimeout(() => {
        setHideContent(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setHideContent(false);
    }
  }, [hash, location]);

  return hideContent;
};

export default useHideContentHash;
