export const skillsIcons = [
  {
    name: "HTML",
    icon: "./images/skills/HTML.svg",
  },
  {
    name: "CSS",
    icon: "./images/skills/CSS.svg",
  },
  {
    name: "JavaScript",
    icon: "./images/skills/Js.svg",
  },
  {
    name: "TypeScript",
    icon: "./images/skills/TypeScript.svg",
  },
  {
    name: "React",
    icon: "./images/skills/React-Dark.svg",
  },
  {
    name: "Redux",
    icon: "./images/skills/Redux.svg",
  },
  {
    name: "Remix",
    icon: "./images/skills/Remix-Dark.svg",
  },
  {
    name: "jQuery",
    icon: "./images/skills/JQuery.svg",
  },
  {
    name: "Git",
    icon: "./images/skills/Git.svg",
  },
  {
    name: "Firebase",
    icon: "./images/skills/Firebase-Dark.svg",
  },
  {
    name: "Material UI",
    icon: "./images/skills/MaterialUI-Dark.svg",
  },
  {
    name: "Tailwind",
    icon: "./images/skills/TailwindCSS-Dark.svg",
  },
  {
    name: "Figma",
    icon: "./images/skills/Figma-Dark.svg",
  },
  {
    name: "Jira",
    icon: "./images/skills/Jira.svg",
  },
  {
    name: "NPM",
    icon: "./images/skills/NPM.svg",
  },
  {
    name: "VS Code",
    icon: "./images/skills/VSCode-Dark.svg",
  },
];
