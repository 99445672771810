import {
  faClipboardCheck,
  faEnvelope,
  faHome,
  faInfoCircle,
  faListAlt,
  faStream
} from "@fortawesome/free-solid-svg-icons";

import { Home } from "pages/home/Home";
import { About } from "pages/about/About";
import { Timeline } from "pages/timeline/Timeline";
import { Skills } from "pages/skills/Skills";
import { Contact } from "pages/contact/Contact";
import { Projects } from "pages/projects/Projects";

export const headerLinks = [
  { name: "Home", icon: faHome, href: "#home", component: <Home /> },
  { name: "About", icon: faInfoCircle, href: "#about", component: <About /> },
  {
    name: "Skills",
    icon: faClipboardCheck,
    href: "#skills",
    component: <Skills />
  },
  {
    name: "Timeline",
    icon: faStream,
    href: "#timeline",
    component: <Timeline />
  },
  {
    name: "Projects",
    icon: faListAlt,
    href: "#projects",
    component: <Projects />
  },
  {
    name: "Contact",
    icon: faEnvelope,
    href: "#contact",
    component: <Contact />
  }
];
