import ReactTypingEffect from "react-typing-effect";

import {
  ContentWrapper,
  DescriptionText,
  IntroductionText,
  LeftWrapper,
  NameText,
  PageWrapper,
  ScrollDown,
  ScrollDownText,
  TypingText,
  Image,
  ImageWrapper,
  RightWrapper,
  TypingTextWrapper,
  StyledButton,
  ButtonsWrapper,
  TypingTextContent,
} from "./Home.styles";
import { PropsType } from "./Home.types";
import RevealEffect from "components/reveal-effect/RevealEffect";
import { headerLinks } from "utils/header-links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const Home: React.FC<PropsType> = () => {
  return (
    <>
      <PageWrapper>
        <ContentWrapper>
          <LeftWrapper>
            <RevealEffect effect="fade" direction="up">
              <IntroductionText>Hello, my name is</IntroductionText>
            </RevealEffect>
            <RevealEffect effect="fade" direction="left">
              <NameText>LUCCAS RODRIGUES</NameText>
            </RevealEffect>
            <RevealEffect effect="fade" direction="down">
              <TypingTextWrapper>
                <ReactTypingEffect
                  text={[
                    "Front-end Developer",
                    "Creative Developer",
                    "Dedicated Developer",
                  ]}
                  displayTextRenderer={(displayText) => (
                    <TypingTextContent>
                      I'm a <TypingText>{displayText}</TypingText>
                    </TypingTextContent>
                  )}
                  cursor=" "
                  cursorClassName="cursorTyping"
                  speed={150}
                  typingDelay={200}
                  eraseDelay={1000}
                  eraseSpeed={70}
                />
              </TypingTextWrapper>
            </RevealEffect>
            <RevealEffect effect="fade" direction="right">
              <DescriptionText>
                Welcome to my Portfolio! Here you will discover more about me,
                my background, experience, and passion for coding. I hope you
                enjoy it!
              </DescriptionText>
            </RevealEffect>
            <ButtonsWrapper>
              <RevealEffect effect="fade" direction="left">
                <StyledButton
                  className="bold"
                  withBackground
                  href="/Luccas_Rodrigues_CV.pdf"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    color="white"
                    icon={faDownload as IconProp}
                  />
                  Download CV
                </StyledButton>
              </RevealEffect>
              <RevealEffect effect="fade" direction="right">
                <StyledButton
                  className="bold"
                  href="https://www.linkedin.com/in/luccas-rodrigues/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    color="white"
                    icon={faLinkedin as IconProp}
                  />
                  Linkedin
                </StyledButton>
              </RevealEffect>
            </ButtonsWrapper>
          </LeftWrapper>
          <RightWrapper>
            <ImageWrapper>
              <RevealEffect effect="fade" direction="up">
                <Image
                  draggable={false}
                  alt="me"
                  src="images/welcome_me.webp"
                />
              </RevealEffect>
            </ImageWrapper>
          </RightWrapper>
        </ContentWrapper>
      </PageWrapper>
      <ScrollDown href={headerLinks[1].href}>
        <RevealEffect effect="fade" direction="down">
          <div className="flex flex-col">
            <img
              width={16}
              height={26}
              alt="scroll"
              src="./images/mouse-scroll.svg"
            />
            <ScrollDownText>SCROLL DOWN</ScrollDownText>
          </div>
        </RevealEffect>
      </ScrollDown>
    </>
  );
};
