import styled from "@emotion/styled";
import { media } from "utils/custom-media";
import { StylingProps } from "./Home.types";
import { css, keyframes } from "@emotion/react";

const spacing = {
  desktop: "8px",
  mobile: "12px",
};

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 5% 0;
  height: 100%;
  gap: 20px;

  ${media.extraSmallMedia} {
    flex-direction: column;
  }

  ${media.mediumMedia} {
    padding: 0 7% 0;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;

  ${media.gteMediumMedia} {
    flex: 0 0 60%;
    max-width: 60%;
  }
`;

export const RightWrapper2 = styled.div`
  display: flex;
  flex: 0 0 40%;
  width: 500px;
`;

export const IntroductionText = styled.p`
  font-size: 40px;
  margin-bottom: ${spacing.desktop};
  font-weight: bold;
  font-family: var(--font-bold);

  ${media.lteSmallMedia} {
    font-size: 20px;
    margin-bottom: ${spacing.mobile};
  }
`;

export const NameText = styled.p`
  font-size: 69px;
  margin-bottom: ${spacing.desktop};
  font-family: var(--font-bold);
  line-height: normal;

  ${media.lteMediumMedia} {
    font-size: 36px;
    margin-bottom: ${spacing.mobile};
  }

  ${media.mediumMedia} {
    font-size: 55px;
  }
`;

export const TypingTextWrapper = styled.div`
  padding-bottom: ${spacing.desktop};

  ${media.lteSmallMedia} {
    padding-bottom: ${spacing.mobile};
  }
`;

export const TypingTextContent = styled.p`
  font-size: 40px;
  font-family: var(--font-bold);

  ${media.lteSmallMedia} {
    font-size: 28px;
  }

  ${media.smallMedia} {
    font-size: 25px;
  }
`;

export const TypingText = styled.span`
  color: var(--primary-color);
`;

export const DescriptionText = styled.p`
  font-size: 20px;
  margin-bottom: ${spacing.desktop};
  max-width: 635px;

  ${media.lteSmallMedia} {
    font-size: 16px;
    margin-bottom: ${spacing.mobile};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  & > * {
    width: 200px;
  }

  & > *:nth-of-type(1) {
    margin-right: 20px;
  }

  ${media.lteSmallMedia} {
    & > * {
      width: 100%;
    }
  }
`;

export const StyledButton = styled("a", {
  shouldForwardProp: (prop: string): boolean => prop !== "withBackground",
})<StylingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing.desktop};
  border-radius: 6px;
  border: 2px solid var(--primary-color);
  padding-top: 10px;
  padding-bottom: 10px;
  transition-property: background-color, color;
  transition-duration: 1s;

  & svg {
    padding-right: 5px;
    transition-property: color;
    transition-duration: 1s;
  }

  ${({ withBackground }) =>
    withBackground
      ? css`
          background: var(--primary-color);
          &:hover {
            background: transparent;
            color: var(--primary-color);

            & svg {
              color: var(--primary-color);
            }
          }
        `
      : css`
          background: transparent;
          color: var(--primary-color);

          & svg {
            color: var(--primary-color);
          }

          &:hover {
            background: var(--primary-color);
            color: white;

            & svg {
              color: white;
            }
          }
        `}

  ${media.lteSmallMedia} {
    margin-top: ${spacing.mobile};
  }
`;

export const LinkedinButton = styled.button`
  margin-top: ${spacing.desktop};
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  background: transparent;
  width: 40px;
  height: 40px;
  transition-duration: 0.5s;
  transition-property: background-color;

  &:hover {
    background: var(--primary-color);
    box-shadow: 0 0 20px var(--primary-color);

    & svg {
      color: #1f242d;
    }
  }
`;

const floating = keyframes`
0% { transform: translate(0,  -5px); }
50%  { transform: translate(0, 10px); }
100%   { transform: translate(0, -5px); }   
`;

export const RightWrapper = styled.div`
  display: flex;
  flex: 0 0 40%;
  width: 100%;

  ${media.gteSmallMedia} {
    // width: 400px;
  }

  ${media.largeMedia} {
    // width: 500px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  // height: 520px;
  // height: 100%;
  user-select: none;
  overflow: hidden;

  ${media.extraSmallMedia} {
    justify-content: center;
    width: 100%;
  }

  ${media.lteSmallMedia} {
    height: auto;
  }
`;

export const Image = styled.img`
  width: 240px;
  height: auto;
  // animation: ${floating} 3s ease-in-out infinite;

  ${media.smallMedia} {
    width: 420px;
  }

  ${media.gteMediumMedia} {
    width: 500px;
  }
`;
//  ------------------------------------------------------------------
export const IntroText = styled.div`
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 10px;

  ${media.smallMedia} {
    font-size: 80px;
  }

  ${media.mediumMedia} {
    font-size: 100px;
  }

  ${media.largeMedia} {
    font-size: 120px;
  }
`;

export const ScrollDown = styled.a`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;

  & img {
    align-self: center;
  }

  ${media.lteSmallMedia} {
    bottom: 15px;
  }
`;

export const ScrollDownText = styled.span`
  margin-top: 15px;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
`;

export const IntroTitle = styled.p`
  font-family: var(--font-regular);
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 0.02em;

  ${media.lteSmallMedia} {
    font-size: 16px;
  }
`;

export const IntroDescription = styled.p`
  font-family: var(--font-regular);
  max-width: 620px;
  width: 100%;
  margin: 0 auto 30px;

  ${media.lteSmallMedia} {
    margin-left: 20px;
    margin-right: 20px;
    width: unset;
    line-height: 1.6;
  }

  ${media.smallMedia} {
    font-size: 18px;
  }

  ${media.mediumMedia} {
    font-size: 18px;
  }

  ${media.largeMedia} {
    font-size: 20px;
  }
`;

export const IntroDescriptionWrapper = styled.div`
  font-family: var(--font-regular);
  font-size: 1.5rem;
  line-height: 2rem;
  max-width: 56rem;
`;
