import React, { useMemo } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { RevealEffectProps } from "./RevealEffect.types";
import { Wrapper } from "./RevealEffect.styles";

const RevealEffect: React.FC<RevealEffectProps> = ({
  children,
  effect,
  direction,
  delay,
  duration = 2000,
  distance,
  disabled,
  ...remainingProps
}) => {
  const renderContent = useMemo(() => {
    if (disabled) {
      return children;
    }
    return (
      <Slide direction={direction} delay={delay} duration={duration}>
        <Fade delay={delay} duration={duration}>
          {children}
        </Fade>
      </Slide>
    );
  }, [children, direction, delay, duration, disabled]);

  return <Wrapper {...remainingProps}>{renderContent}</Wrapper>;
};

export default RevealEffect;
