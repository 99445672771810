import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { media } from "utils/custom-media";

export const HeaderWrapper = styled.header`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10% 0;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999999;

  ${media.lteSmallMedia} {
    background-color: #e3e4e5;
    padding: 5px 15px;
    height: 51px;
  }
`;

interface ActiveProps {
  isActive?: boolean;
  isOpen?: boolean;
}

export const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  transition: all 0.3s;

  &:hover {
    // transform: scale(1.02);
  }

  ${media.extraSmallMedia} {
    // position: absolute;
    // top: 50px;
    // left: 0px;
    // padding-left: 15px;
  }
`;

export const CircleLogo = styled.div`
  overflow: hidden;
  border-radius: 50%;
  background-color: rgba(27, 49, 96, 0.4);
  width: 60px;
  height: 60px;

  ${media.extraSmallMedia} {
    // display: none;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #fff;
  line-height: 23px;
  font-size: 32px;
  font-family: var(--font-bold);

  ${media.lteSmallMedia} {
    color: #323232;
    font-size: 28px;
    margin-left: 0;
  }
`;

export const SmallName = styled.small`
  font-size: 80%;
  font-weight: 400;
`;

export const OpenMenuButton = styled.button`
  display: none;
  line-height: 0;

  & svg {
    color: #323232;
    height: 25px;
  }

  ${media.lteSmallMedia} {
    display: block;
  }
`;

export const Menu = styled("nav", {
  shouldForwardProp: (prop: string): boolean => prop !== "isOpen",
})<ActiveProps>`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 15px;
    display: inline-block;
    margin-left: 30px;
  }

  li:first-of-type {
    ${media.gteSmallMedia} {
      margin-left: 0;
    }
  }

  ${media.lteSmallMedia} {
    position: absolute;
    left: -15px;
    right: -15px;
    border: 0;
    box-shadow: none;
    text-align: left;
    top: 100%;

    ul {
      background: white;
      display: flex;
      flex-direction: column;
      transition: max-height 0.3s ease-in-out;
      ${({ isOpen }) =>
        isOpen
          ? css`
              max-height: 455px;
            `
          : css`
              max-height: 0;
            `}
      overflow: hidden;
    }

    li {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      margin-left: 0;
    }

    li svg {
      color: #222222;
    }
  }
`;

export const HeaderList = styled.ul`
  display: flex;
  align-items: center;
`;

export const NavLink = styled("a", {
  shouldForwardProp: (prop: string): boolean => prop !== "isActive",
})<ActiveProps>`
  text-decoration: none;
  font-family: var(--font-bold);
  letter-spacing: 0.5px;
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 2px;
    // background: #fff;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: scale(0);
    transform: scale(0);
    // margin-top: 4px;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }

  &:hover {
    color: var(--primary-color);
    transition: all 0.4s ease;

    &::after {
      transform: scale(1);
      background-color: var(--primary-color);
    }
  }

  ${media.lteSmallMedia} {
    color: #222222;

    &::after {
      background-color: #222222;
      display: none;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--primary-color) !important;

      & svg {
        color: var(--primary-color) !important;
      }

      &::after {
        transform: scale(1);
        background-color: var(--primary-color);
      }
    `}
`;
